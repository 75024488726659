/* You can add global styles to this file, and also import other style files */
@import '~@xpo-ltl/ngx-ltl-core/styles/variables';
@import '~@xpo-ltl/ngx-ltl-core/styles/reset';

.xpo-mover-detail {
  .mat-expansion-panel-content {
    position: relative;
    top: -30px;
  }
}

.xpo-shipment-tracking {
  .mat-expansion-panel-content {
    position: relative;
    top: -20px;
  }
}

.xpo-maint-ui {
  width: 100%;
}
